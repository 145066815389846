<template>
	<div id="qualification-query-wapper">
		<br>
		<ul class="query-items">
			<li>
				<p class="textleft">请输入要查询的运动员姓名</p>
				<el-input
					class="query-items-input"
					v-model="inputName"
					placeholder="姓名"
				></el-input>
			</li>
			<li>
				<el-button
					style="margin-top: 0.5rem; width: 10rem"
					type="primary"
					@click="submit()"
					>查询</el-button
				>
			</li>
			<li>
				<el-table
					:data="tableData"
					style="width: 100%">
					<el-table-column
						prop="res">
					</el-table-column>
				</el-table>
			</li>
		</ul>
		
	</div>
</template>

<style scoped>
.query-items {
	list-style-type: none;
	display: block;
	width: 40rem;
	margin: auto;
}
.query-items >>> li {
	margin-bottom: 5px;
}
.query-items .textleft {
	text-align: left;
	position: relative;
	padding-left: 8px;
	border-left: 4px solid #1fcab3;
}
.query-items-input {
	width: 100%;
}
li .addbtn {
	margin: 0.7rem 5%;
	width: 35%;
}
</style>

<script>
import axios from "axios";
import mixin from "../../mixins/universalmixins.js";
export default {
	name: "QualificationQuery",
	mixins: [mixin],
	data() {
		return {
            inputName: "",
			tableData: []
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
		async submit() {
			if (this.inputName.length === 0) return;
			axios({
				url: "/api/qual/query?name="+this.inputName,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
			.then(async (res) => {
				console.log(res);
				this.tableData = []
				for (let info of res.data.data) {
					console.log(info)
					this.tableData.push({"res": info.name + "，学号" + info.id + "，学生证院系为" + info.dept +
					"，经" + info.from + "认定本学年代表" + info.for + "参赛。"});
				}
				await this.messageBox("查询成功", "success");
			})
			.catch((err) => {
				this.messageBox(
					"查询失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
			});
		},
	},
};
</script>